import React, { useRef } from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
// Components
import { Layout } from "components/Layout";
import Page, { PageSidebar, PageTitle } from "components/Page";
import HTMLContent from "components/HTMLContent";
import HeadTags from "components/Head";
// Types
import { MarkdownContent } from "types/MarkdownRemark";
import { CategoryContext } from "types/PageContext";

type DataProps = {
  markdownRemark: MarkdownContent;
};

export const CategoryTemplate: React.FC<PageProps<
  DataProps,
  CategoryContext
>> = ({ data }) => {
  console.log(data);
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title, slug } = frontmatter;
  const htmlContentRef = useRef<HTMLDivElement>(null);
  return (
    <Layout title={title} slug={slug}>
      <Page id={slug}>
        <PageTitle>{title}</PageTitle>
        <HTMLContent ref={htmlContentRef}>{html}</HTMLContent>
      </Page>
      <PageSidebar contentRef={htmlContentRef} />
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps, CategoryContext>) => (
  <HeadTags {...props} />
);

export default CategoryTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

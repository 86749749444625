import React, { forwardRef } from "react";
import Box, { BoxProps } from "@mui/joy/Box";

export interface HTMLContentProps extends Partial<BoxProps> {
  children: string;
}

export const HTMLContent = forwardRef<HTMLDivElement, HTMLContentProps>(
  ({ children, ...other }, ref) => (
    <Box
      ref={ref}
      component="article"
      className="content"
      sx={(theme) => ({
        overflowY: "auto",
        "& h2": theme.typography.h2,
        "& h3": theme.typography.h3,
        "& h4": theme.typography.h4,
        "& h5": theme.typography.h5,
        "& h6": theme.typography.h6,
        "& p,td": theme.typography.body1,
        "& a": {
          ...theme.typography.body1,
          color: theme.palette.primary.plainColor,
        },
        "& table": {
          "& th": theme.typography.h4,
          padding: 2,
          "& tr": {
            backgroundColor: theme.palette.background.componentBg,
            border: `${1} solid`,
            borderColor: theme.palette.divider,
          },
          "& td": {
            verticalAlign: "inherit",
            padding: 2,
            display: "table-cell",
            borderBottom: `${1} solid ${theme.palette.divider}`,
          },
        },
        "& blockquote": {
          paddingX: 2,
          paddingY: 1,
          border: `${1} solid`,
          borderWidth: "var(--corsiaverse-border)",
          borderColor: theme.palette.primary.plainColor,
        },
      })}
      {...other}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
);
export default HTMLContent;
